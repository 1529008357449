import { StaticImageData } from "next/future/image";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { $enum } from "ts-enum-util";
import ArtImg from "../../public/categories/art.png";
import BusinessImg from "../../public/categories/business.png";
import CsImg from "../../public/categories/cs.png";
import EducationImg from "../../public/categories/education.png";
import EngineerImg from "../../public/categories/engineer.png";
import HealthImg from "../../public/categories/health.png";
import HumanitiesImg from "../../public/categories/humanities.png";
import MathImg from "../../public/categories/math.png";
import PersonalImg from "../../public/categories/personal.png";
import ScicenseImg from "../../public/categories/science.png";

export enum Category {
  ComputerScience = "Computer Science",
  Business = "Business",
  Humanities = "Humanities",
  PersonalDevelopment = "Personal Development",
  ArtDesign = "Art & Design",
  Engineering = "Engineering",
  HealthMedicine = "Health & Medicine",
  Mathematics = "Mathematics",
  Science = "Science",
  Education = "Education",
}

export const activeCategories: Category[] = $enum(Category).getValues();

interface CategoryConfig {
  name: string;
  img: StaticImageData;
  slug: string;
}

export const categoryConfigs: {
  [key in Category]: CategoryConfig;
} = {
  [Category.ComputerScience]: {
    name: "Computer Science",
    img: CsImg,
    slug: "computer-science",
  },
  [Category.Business]: {
    name: "Business",
    img: BusinessImg,
    slug: "business",
  },
  [Category.Humanities]: {
    name: "Humanities",
    img: HumanitiesImg,
    slug: "humanities",
  },
  [Category.PersonalDevelopment]: {
    name: "Personal Development",
    img: PersonalImg,
    slug: "personal-development",
  },
  [Category.ArtDesign]: {
    name: "Art & Design",
    img: ArtImg,
    slug: "art-design",
  },
  [Category.Engineering]: {
    name: "Engineering",
    img: EngineerImg,
    slug: "engineering",
  },
  [Category.HealthMedicine]: {
    name: "Health & Medicine",
    img: HealthImg,
    slug: "health-medicine",
  },
  [Category.Mathematics]: { name: "Mathematics", img: MathImg, slug: "math" },
  [Category.Science]: { name: "Science", img: ScicenseImg, slug: "science" },
  [Category.Education]: {
    name: "Education",
    img: EducationImg,
    slug: "education",
  },
};

export function useCategory(): CategoryConfig {
  const { query } = useRouter();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useMemo(() => {
    const slug = query.slug as string | undefined;
    return categoryConfig(slug ?? "");
  }, [query])!;
}

export function categoryConfig(slug: string): CategoryConfig | undefined {
  return Object.values(categoryConfigs).filter((v) => v.slug === slug)[0];
}
