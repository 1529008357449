import { ButtonHTMLAttributes } from "react";

export const Button = ({
  className,
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    className={
      "px-6 py-3 border border-transparent rounded-md text-white \
                  bg-gray-700 shadow-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 \
                  focus:ring-gray-800 " +
      className
    }
    {...props}
  >
    {children}
  </button>
);
