/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import { SortOption, QueryResponse, ElasticSearchResponse, ElasticSearchRequest, ElasticQuerySuggestionResponse } from "./types";
import { Platform, Level } from "./model";
import { Category } from "./category";

export function isSortOption(obj: any, _argumentName?: string): obj is SortOption {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        typeof obj.field === "string" &&
        (obj.direction === "asc" ||
            obj.direction === "desc")
    )
}

export function isQueryResponse(obj: any, _argumentName?: string): obj is QueryResponse {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        typeof obj.current === "number" &&
        typeof obj.totalPages === "number" &&
        typeof obj.totalResults === "number" &&
        typeof obj.pageSize === "number" &&
        (obj.facets !== null &&
            typeof obj.facets === "object" ||
            typeof obj.facets === "function") &&
        Object.entries<any>(obj.facets)
            .every(([key, value]) => (Array.isArray(value) &&
                value.every((e: any) =>
                    (e !== null &&
                        typeof e === "object" ||
                        typeof e === "function") &&
                    typeof e.count === "number" &&
                    typeof e.value === "string"
                ) &&
                typeof key === "string")) &&
        Array.isArray(obj.results) &&
        obj.results.every((e: any) =>
            (e !== null &&
                typeof e === "object" ||
                typeof e === "function") &&
            typeof e.id === "string" &&
            (e.thumbnail === null ||
                typeof e.thumbnail === "string") &&
            (e.photo === null ||
                typeof e.photo === "string") &&
            typeof e.title === "string" &&
            (e.headline === null ||
                typeof e.headline === "string") &&
            typeof e.url === "string" &&
            (e.platform === Platform.Coursera ||
                e.platform === Platform.Udemy ||
                e.platform === Platform.Edx ||
                e.platform === Platform.Udacity ||
                e.platform === Platform.Skillshare) &&
            typeof e.descriptionSnippet === "string" &&
            typeof e.description === "string" &&
            (e.effort === null ||
                typeof e.effort === "string") &&
            (e.level === null ||
                e.level === Level.Introductory ||
                e.level === Level.Intermediate ||
                e.level === Level.Advanced) &&
            Array.isArray(e.categories) &&
            e.categories.every((e: any) =>
            (e === Category.ComputerScience ||
                e === Category.Business ||
                e === Category.Humanities ||
                e === Category.PersonalDevelopment ||
                e === Category.ArtDesign ||
                e === Category.Engineering ||
                e === Category.HealthMedicine ||
                e === Category.Mathematics ||
                e === Category.Science ||
                e === Category.Education)
            ) &&
            Array.isArray(e.rawCategories) &&
            e.rawCategories.every((e: any) =>
                typeof e === "string"
            ) &&
            typeof e.isFree === "boolean" &&
            Array.isArray(e.subcategories) &&
            e.subcategories.every((e: any) =>
                typeof e === "string"
            ) &&
            typeof e.hasCertificate === "boolean" &&
            Array.isArray(e.siteSubscriptions) &&
            e.siteSubscriptions.every((e: any) =>
                typeof e === "string"
            ) &&
            Array.isArray(e.courseSubscriptions) &&
            e.courseSubscriptions.every((e: any) =>
                typeof e === "string"
            ) &&
            (e.price === null ||
                typeof e.price === "number") &&
            (e.contentLength === null ||
                typeof e.contentLength === "number") &&
            (e.publishedAt === null ||
                typeof e.publishedAt === "string") &&
            (e.updatedAt === null ||
                typeof e.updatedAt === "string") &&
            Array.isArray(e.instructors) &&
            e.instructors.every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                typeof e.displayName === "string" &&
                (e.avatar === null ||
                    typeof e.avatar === "string") &&
                (e.jobTitle === null ||
                    typeof e.jobTitle === "string") &&
                (e.institution === null ||
                    typeof e.institution === "string")
            ) &&
            Array.isArray(e.institutions) &&
            e.institutions.every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                typeof e.displayName === "string" &&
                (e.longLogo === null ||
                    typeof e.longLogo === "string") &&
                (e.shortLogo === null ||
                    typeof e.shortLogo === "string")
            ) &&
            typeof e.language === "string" &&
            Array.isArray(e.captionLanguages) &&
            e.captionLanguages.every((e: any) =>
                typeof e === "string"
            )
        )
    )
}

export function isElasticSearchResponse(obj: any, _argumentName?: string): obj is ElasticSearchResponse {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        (typeof obj.facets === "undefined" ||
            (obj.facets !== null &&
                typeof obj.facets === "object" ||
                typeof obj.facets === "function") &&
            Object.entries<any>(obj.facets)
                .every(([key, value]) => (Array.isArray(value) &&
                    value.every((e: any) =>
                        (e !== null &&
                            typeof e === "object" ||
                            typeof e === "function") &&
                        Array.isArray(e.data) &&
                        e.data.every((e: any) =>
                            (e !== null &&
                                typeof e === "object" ||
                                typeof e === "function") &&
                            typeof e.count === "number" &&
                            typeof e.value === "string"
                        ) &&
                        typeof e.type === "string"
                    ) &&
                    typeof key === "string"))) &&
        (obj.meta !== null &&
            typeof obj.meta === "object" ||
            typeof obj.meta === "function") &&
        (obj.meta.page !== null &&
            typeof obj.meta.page === "object" ||
            typeof obj.meta.page === "function") &&
        typeof obj.meta.page.current === "number" &&
        typeof obj.meta.page.total_pages === "number" &&
        typeof obj.meta.page.total_results === "number" &&
        typeof obj.meta.page.size === "number" &&
        Array.isArray(obj.results) &&
        obj.results.every((e: any) =>
            (e !== null &&
                typeof e === "object" ||
                typeof e === "function") &&
            (e.description !== null &&
                typeof e.description === "object" ||
                typeof e.description === "function") &&
            typeof e.description.snippet === "string" &&
            (e.title !== null &&
                typeof e.title === "object" ||
                typeof e.title === "function") &&
            typeof e.title.raw === "string" &&
            (e.platform !== null &&
                typeof e.platform === "object" ||
                typeof e.platform === "function") &&
            typeof e.platform.raw === "string" &&
            (e.instructors !== null &&
                typeof e.instructors === "object" ||
                typeof e.instructors === "function") &&
            Array.isArray(e.instructors.raw) &&
            e.instructors.raw.every((e: any) =>
                typeof e === "string"
            ) &&
            (e.course_detail_photo_url !== null &&
                typeof e.course_detail_photo_url === "object" ||
                typeof e.course_detail_photo_url === "function") &&
            (e.course_detail_photo_url.raw === null ||
                typeof e.course_detail_photo_url.raw === "string") &&
            (e.sub_categories !== null &&
                typeof e.sub_categories === "object" ||
                typeof e.sub_categories === "function") &&
            Array.isArray(e.sub_categories.raw) &&
            e.sub_categories.raw.every((e: any) =>
                typeof e === "string"
            ) &&
            (e.course_language !== null &&
                typeof e.course_language === "object" ||
                typeof e.course_language === "function") &&
            typeof e.course_language.raw === "string" &&
            (e.updated_at !== null &&
                typeof e.updated_at === "object" ||
                typeof e.updated_at === "function") &&
            (e.updated_at.raw === null ||
                typeof e.updated_at.raw === "string") &&
            (e.raw_course_url !== null &&
                typeof e.raw_course_url === "object" ||
                typeof e.raw_course_url === "function") &&
            typeof e.raw_course_url.raw === "string" &&
            (e.price !== null &&
                typeof e.price === "object" ||
                typeof e.price === "function") &&
            (e.price.raw === null ||
                typeof e.price.raw === "number") &&
            (e.categories !== null &&
                typeof e.categories === "object" ||
                typeof e.categories === "function") &&
            Array.isArray(e.categories.raw) &&
            e.categories.raw.every((e: any) =>
                typeof e === "string"
            ) &&
            (e.id !== null &&
                typeof e.id === "object" ||
                typeof e.id === "function") &&
            typeof e.id.raw === "string" &&
            (e.published_at !== null &&
                typeof e.published_at === "object" ||
                typeof e.published_at === "function") &&
            (e.published_at.raw === null ||
                typeof e.published_at.raw === "string") &&
            (e.headline !== null &&
                typeof e.headline === "object" ||
                typeof e.headline === "function") &&
            (e.headline.raw === null ||
                typeof e.headline.raw === "string") &&
            (e.content_length !== null &&
                typeof e.content_length === "object" ||
                typeof e.content_length === "function") &&
            (e.content_length.raw === null ||
                typeof e.content_length.raw === "number") &&
            (e.site_subscriptions !== null &&
                typeof e.site_subscriptions === "object" ||
                typeof e.site_subscriptions === "function") &&
            Array.isArray(e.site_subscriptions.raw) &&
            e.site_subscriptions.raw.every((e: any) =>
                typeof e === "string"
            ) &&
            (e.level !== null &&
                typeof e.level === "object" ||
                typeof e.level === "function") &&
            (e.level.raw === null ||
                typeof e.level.raw === "string") &&
            (e.has_certificate !== null &&
                typeof e.has_certificate === "object" ||
                typeof e.has_certificate === "function") &&
            typeof e.has_certificate.raw === "string" &&
            (e.effort_description !== null &&
                typeof e.effort_description === "object" ||
                typeof e.effort_description === "function") &&
            (e.effort_description.raw === null ||
                typeof e.effort_description.raw === "string") &&
            (e.raw_description !== null &&
                typeof e.raw_description === "object" ||
                typeof e.raw_description === "function") &&
            typeof e.raw_description.raw === "string" &&
            (e.institutions !== null &&
                typeof e.institutions === "object" ||
                typeof e.institutions === "function") &&
            Array.isArray(e.institutions.raw) &&
            e.institutions.raw.every((e: any) =>
                typeof e === "string"
            ) &&
            (e.raw_categories !== null &&
                typeof e.raw_categories === "object" ||
                typeof e.raw_categories === "function") &&
            Array.isArray(e.raw_categories.raw) &&
            e.raw_categories.raw.every((e: any) =>
                typeof e === "string"
            ) &&
            (e.course_subscriptions !== null &&
                typeof e.course_subscriptions === "object" ||
                typeof e.course_subscriptions === "function") &&
            Array.isArray(e.course_subscriptions.raw) &&
            e.course_subscriptions.raw.every((e: any) =>
                typeof e === "string"
            ) &&
            (e.is_free !== null &&
                typeof e.is_free === "object" ||
                typeof e.is_free === "function") &&
            typeof e.is_free.raw === "string" &&
            (e.course_search_photo_url !== null &&
                typeof e.course_search_photo_url === "object" ||
                typeof e.course_search_photo_url === "function") &&
            (e.course_search_photo_url.raw === null ||
                typeof e.course_search_photo_url.raw === "string") &&
            (e.caption_languages !== null &&
                typeof e.caption_languages === "object" ||
                typeof e.caption_languages === "function") &&
            Array.isArray(e.caption_languages.raw) &&
            e.caption_languages.raw.every((e: any) =>
                typeof e === "string"
            )
        )
    )
}

export function isElasticSearchRequest(obj: any, _argumentName?: string): obj is ElasticSearchRequest {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        typeof obj.query === "string" &&
        (typeof obj.page === "undefined" ||
            (obj.page !== null &&
                typeof obj.page === "object" ||
                typeof obj.page === "function") &&
            (typeof obj.page.size === "undefined" ||
                typeof obj.page.size === "number") &&
            (typeof obj.page.current === "undefined" ||
                typeof obj.page.current === "number")) &&
        (typeof obj.sort === "undefined" ||
            Array.isArray(obj.sort) &&
            obj.sort.every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                Object.entries<any>(e)
                    .every(([key, value]) => ((value === "asc" ||
                        value === "desc") &&
                        typeof key === "string"))
            )) &&
        (typeof obj.facets === "undefined" ||
            (obj.facets !== null &&
                typeof obj.facets === "object" ||
                typeof obj.facets === "function") &&
            Object.entries<any>(obj.facets)
                .every(([key, value]) => ((value !== null &&
                    typeof value === "object" ||
                    typeof value === "function") &&
                    value.type === "value" &&
                    (typeof value.size === "undefined" ||
                        typeof value.size === "number") &&
                    typeof key === "string"))) &&
        (typeof obj.filters === "undefined" ||
            (obj.filters !== null &&
                typeof obj.filters === "object" ||
                typeof obj.filters === "function") &&
            Array.isArray(obj.filters.all) &&
            obj.filters.all.every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                Object.entries<any>(e)
                    .every(([key, value]) => (Array.isArray(value) &&
                        value.every((e: any) =>
                            typeof e === "string"
                        ) &&
                        typeof key === "string"))
            ))
    )
}

export function isElasticQuerySuggestionResponse(obj: any, _argumentName?: string): obj is ElasticQuerySuggestionResponse {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        (obj.results !== null &&
            typeof obj.results === "object" ||
            typeof obj.results === "function") &&
        Array.isArray(obj.results.documents) &&
        obj.results.documents.every((e: any) =>
            (e !== null &&
                typeof e === "object" ||
                typeof e === "function") &&
            typeof e.suggestion === "string"
        )
    )
}
