import {
  faCalendar,
  faClosedCaptioning,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCertificate,
  faPencil,
  faTree,
  faTv,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import humanizeDuration from "humanize-duration";
import { round } from "lodash";
import { DateTime } from "luxon";
import { StaticImageData } from "next/future/image";
import { ReactNode } from "react";
import { $enum } from "ts-enum-util";
import courseraFullLogo from "../../public/platforms/coursera-full.png";
import courseraSquareLogo from "../../public/platforms/coursera-square.png";
import edxFullLogo from "../../public/platforms/edx-full.png";
import edxSquareLogo from "../../public/platforms/edx-square.png";
import udacityFullLogo from "../../public/platforms/udacity-full.png";
import udacitySquareLogo from "../../public/platforms/udacity-square.png";
import udemyFullLogo from "../../public/platforms/udemy-full.png";
import udemySquareLogo from "../../public/platforms/udemy-square.png";
import skillshareFullLogo from "../../public/platforms/skillshare-full.png";
import skillshareSquareLogo from "../../public/platforms/skillshare-square.png";
import { Category } from "./category";
import { SearchResult } from "./types";

export enum Platform {
  Coursera,
  Udemy,
  Edx,
  Udacity,
  Skillshare,
}

export const platformConfig: {
  [key in Platform]: { full: StaticImageData; square: StaticImageData };
} = {
  [Platform.Coursera]: { full: courseraFullLogo, square: courseraSquareLogo },
  [Platform.Edx]: { full: edxFullLogo, square: edxSquareLogo },
  [Platform.Udacity]: { full: udacityFullLogo, square: udacitySquareLogo },
  [Platform.Udemy]: { full: udemyFullLogo, square: udemySquareLogo },
  [Platform.Skillshare]: {
    full: skillshareFullLogo,
    square: skillshareSquareLogo,
  },
};

export enum Level {
  Introductory,
  Intermediate,
  Advanced,
}

export interface Instructor {
  displayName: string;
  avatar: string | null;
  jobTitle: string | null;
  institution: string | null;
}

export interface Institution {
  displayName: string;
  longLogo: string | null;
  shortLogo: string | null;
}

export interface Course {
  id: string;
  thumbnail: string | null;
  photo: string | null;
  title: string;
  headline: string | null;
  url: string;
  platform: Platform;
  descriptionSnippet: string;
  description: string;
  effort: string | null;
  level: Level | null;
  categories: Category[];
  rawCategories: string[];
  isFree: boolean;
  subcategories: string[];
  hasCertificate: boolean;
  siteSubscriptions: string[];
  courseSubscriptions: string[];
  price: number | null;
  contentLength: number | null;
  // This should be ISO datetime, but we use string to work with JSON
  publishedAt: string | null;
  updatedAt: string | null;
  instructors: Instructor[];
  institutions: Institution[];
  language: string;
  captionLanguages: string[];
}

function parseInstructor(entry: Record<string, string | null>): Instructor {
  return {
    displayName: entry["display_name"] ?? "",
    avatar: entry["avatar"],
    jobTitle: entry["job_title"],
    institution: entry["institution"],
  };
}

function parseInstitution(entry: Record<string, string | null>): Institution {
  return {
    displayName: entry["display_name"] ?? "",
    longLogo: entry["long_logo"],
    shortLogo: entry["short_logo"],
  };
}

export function parseElasticEntry(entry: SearchResult): Course {
  return {
    id: entry.id.raw,
    thumbnail: entry.course_search_photo_url.raw,
    photo: entry.course_detail_photo_url.raw,
    title: entry.title.raw,
    headline: entry.headline.raw,
    url: entry.raw_course_url.raw,
    descriptionSnippet: entry.description.snippet,
    description: entry.raw_description.raw,
    platform: $enum(Platform).getValueOrThrow(entry.platform.raw),
    level: entry.level.raw
      ? $enum(Level).getValueOrThrow(entry.level.raw)
      : null,
    categories: entry.categories.raw.map((c) => {
      if ($enum(Category).isValue(c)) {
        return c;
      } else {
        throw `Invalid category ${c}`;
      }
    }),
    rawCategories: entry.raw_categories.raw,
    effort: entry.effort_description.raw,
    subcategories: entry.sub_categories.raw,
    hasCertificate: entry.has_certificate.raw === "true",
    siteSubscriptions: entry.site_subscriptions.raw,
    courseSubscriptions: entry.course_subscriptions.raw,
    institutions: entry.institutions.raw.map((item) =>
      parseInstitution(JSON.parse(item))
    ),
    isFree: entry.is_free.raw === "true",
    price: entry.price.raw ? round(entry.price.raw * 100) / 100 : null,
    instructors: entry.instructors.raw.map((item) =>
      parseInstructor(JSON.parse(item))
    ),
    contentLength: entry.content_length.raw
      ? round(entry.content_length.raw)
      : null,
    publishedAt: entry.published_at.raw
      ? DateTime.fromISO(entry.published_at.raw).toISO()
      : null,
    updatedAt: entry.updated_at.raw
      ? DateTime.fromISO(entry.updated_at.raw).toISO()
      : null,
    language: entry.course_language.raw,
    captionLanguages: entry.caption_languages.raw,
  };
}

export interface Attribute {
  icon: ReactNode;
  title: string;
  content: string;
  fullContent?: string;
}

const languageNameInEnglish = new Intl.DisplayNames(["en"], {
  type: "language",
  fallback: "code",
});

export function getAttributes(course: Course): Attribute[] {
  const result = [];

  if (course.level !== null) {
    result.push({
      icon: <FontAwesomeIcon icon={faTree} />,
      title: "Level",
      content: Level[course.level],
    });
  }

  if (course.effort) {
    result.push({
      icon: <FontAwesomeIcon icon={faPencil} />,
      title: "Effort",
      content: course.effort,
    });
  }

  if (course.contentLength) {
    result.push({
      icon: <FontAwesomeIcon icon={faVideo} />,
      title: "Content length",
      content:
        "~ " +
        humanizeDuration(course.contentLength * 60 * 1000, {
          units: ["h"],
          round: true,
        }),
    });
  }

  if (course.hasCertificate) {
    result.push({
      icon: <FontAwesomeIcon icon={faCertificate} />,
      title: "Certificate",
      content: "Certificate",
      fullContent: "Certificate of Completion",
    });
  }

  if (course.publishedAt) {
    result.push({
      icon: <FontAwesomeIcon icon={faCalendar} />,
      title: "Published at",
      content: DateTime.fromISO(course.publishedAt, {
        setZone: true,
      }).toLocaleString(DateTime.DATE_FULL),
    });
  }

  if (course.language) {
    result.push({
      icon: <FontAwesomeIcon icon={faTv} />,
      title: "Language",
      content: course.language.toUpperCase(),
      fullContent: languageNameInEnglish.of(course.language),
    });
  }

  if (course.captionLanguages.length) {
    result.push({
      icon: <FontAwesomeIcon icon={faClosedCaptioning} />,
      title: "Caption language",
      content: course.captionLanguages.map((l) => l.toUpperCase()).join(", "),
      fullContent: course.captionLanguages
        .map((l) => languageNameInEnglish.of(l))
        .join(", "),
    });
  }

  return result;
}
