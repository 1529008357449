import produce, { Immutable } from "immer";
import { isEqual, max, toInteger, uniq } from "lodash";
import { ParsedUrlQuery } from "querystring";
import { QueryRequest, SortOption } from "./types";
import { isSortOption } from "./types.guard";

const defaultSortOption: SortOption = {
  direction: "desc",
  field: "_score",
};

const defaultPage = 1;
const defaultPageSize = 10;
const defaultSearchTerm = "";
const defaultFilter: string[] = [];

const pageKey = "page";
const pageSizeKey = "size";
const searchTermKey = "query";
const sortFieldKey = "sort-by";
const sortDirectionKey = "sort-order";
const platformFilterKey = "platform";
const categoryFilterKey = "category";
const subcategoryFilterKey = "subcategory";
const mustBeFreeKey = "free";
const mustHaveCertKey = "cert";
const levelKey = "level";

export const defaultQuery: Immutable<QueryRequest> = {
  page: defaultPage,
  pageSize: defaultPageSize,
  searchTerm: defaultSearchTerm,
  sort: defaultSortOption,
  platformFilter: defaultFilter,
  categoryFilter: defaultFilter,
  subcategoryFilter: defaultFilter,
  levelFilter: defaultFilter,
  mustBeFree: false,
  mustHaveCert: false,
};

export const searchParamKeys = {
  pageKey,
  pageSizeKey,
  searchTermKey,
  sortFieldKey,
  sortDirectionKey,
  platformFilterKey,
  categoryFilterKey,
  subcategoryFilterKey,
  mustBeFreeKey,
  mustHaveCertKey,
  levelKey,
};

export function parse(params: Immutable<ParsedUrlQuery>): QueryRequest {
  const strParams = params as Record<string, string>;

  const page = max([toInteger(strParams[pageKey]), 1]) ?? defaultPage;
  const pageSize =
    max([toInteger(strParams[pageSizeKey]), 10]) ?? defaultPageSize;
  const searchTerm = strParams[searchTermKey] ?? defaultSearchTerm;

  const sortParams = {
    field: strParams[sortFieldKey],
    direction: strParams[sortDirectionKey],
  };
  let sort: SortOption;
  if (!isSortOption(sortParams)) {
    sort = defaultSortOption;
  } else {
    sort = sortParams;
  }

  function getFilter(f: string | null): string[] {
    return uniq(
      f
        ?.split(",")
        .map((f) => f.trim())
        .filter((f) => f !== "") ?? defaultFilter
    );
  }

  const platformFilter = getFilter(strParams[platformFilterKey]);
  const categoryFilter = getFilter(strParams[categoryFilterKey]);
  const subcategoryFilter = getFilter(strParams[subcategoryFilterKey]);
  const levelFilter = getFilter(strParams[levelKey]);

  const mustBeFree = !!strParams[mustBeFreeKey];
  const mustHaveCert = !!strParams[mustHaveCertKey];

  const query = {
    page,
    pageSize,
    searchTerm,
    sort,
    platformFilter,
    categoryFilter,
    subcategoryFilter,
    mustBeFree,
    mustHaveCert,
    levelFilter,
  };

  return query;
}

export function updateQuery(
  request: Immutable<QueryRequest>,
  oldQuery: Immutable<ParsedUrlQuery>
): Immutable<ParsedUrlQuery> {
  return produce(oldQuery, (draft) => {
    for (const value of Object.values(searchParamKeys)) {
      delete draft[value];
    }

    const params = toParams(request);
    for (const param of params) {
      draft[param[0]] = param[1];
    }
  });
}

export function toParams(
  query: QueryRequest | Immutable<QueryRequest>
): [string, string][] {
  const result: [string, string][] = [];

  if (query.page !== defaultPage) {
    result.push([pageKey, query.page.toString()]);
  }

  if (query.pageSize !== defaultPageSize) {
    result.push([pageSizeKey, query.pageSize.toString()]);
  }

  if (query.searchTerm !== defaultSearchTerm) {
    result.push([searchTermKey, query.searchTerm]);
  }

  if (!isEqual(query.sort, defaultSortOption)) {
    result.push([sortFieldKey, query.sort.field]);
    result.push([sortDirectionKey, query.sort.direction]);
  }

  if (!isEqual(query.platformFilter, defaultFilter)) {
    result.push([platformFilterKey, query.platformFilter.join(",")]);
  }

  if (!isEqual(query.categoryFilter, defaultFilter)) {
    result.push([categoryFilterKey, query.categoryFilter.join(",")]);
  }

  if (!isEqual(query.subcategoryFilter, defaultFilter)) {
    result.push([subcategoryFilterKey, query.subcategoryFilter.join(",")]);
  }

  if (!isEqual(query.levelFilter, defaultFilter)) {
    result.push([levelKey, query.levelFilter.join(",")]);
  }

  if (query.mustBeFree) {
    result.push([mustBeFreeKey, "1"]);
  }

  if (query.mustHaveCert) {
    result.push([mustHaveCertKey, "1"]);
  }

  return result;
}

export function stringify(
  query: QueryRequest | Immutable<QueryRequest>
): string {
  const params = toParams(query);
  const urlParams = new URLSearchParams();

  for (const param of params) {
    urlParams.append(param[0], param[1]);
  }

  urlParams.sort();

  return urlParams.toString();
}
